import { Table, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BOOK_LESSON_INFO } from 'src/operations/queries/book';
import { useLazyQuery } from '@apollo/client';

const TD = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;
const DailyContent = ({ level, volume, bookData, prefix }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentLesson, setCurrentLesson] = useState(null);
  const culp_token = window.localStorage.getItem('culp_token');
  const [lessonStage, setLessonStage] = useState(null);

  const [getBookLessonInfo, { data: bookLessonData }] = useLazyQuery(BOOK_LESSON_INFO);

  useEffect(() => {
    if (bookLessonData?.bookLessonInfo) {
      setLessonStage(bookLessonData?.bookLessonInfo.stage);
    }
  }, [bookLessonData]);

  const dailyArr = useMemo(() => {
    if (bookData) {
      const findItem = bookData.find((book) => book.level_no === level && book.volume_no === volume);
      let lesson = findItem?.book_lesson
        .filter((lesson) => lesson.stage_group_code !== 'essay')
        .sort((a, b) => {
          if (a.unit_no === b.unit_no) {
            return a.day_no > b.day_no ? 1 : -1;
          } else {
            return a.unit_no > b.unit_no ? 1 : -1;
          }
        });
      return lesson.map((item) => {
        return {
          ...item,
          key: item.lesson_no,
          No: item.lesson_no,
          link_url: item.code ? `${STUDY_TOOL_HOST}/${item.code}?token=${culp_token}` : undefined,
          book_image: findItem?.book_sub[0]?.cover_path || findItem?.book_sub[0]?.ebook_path||`https://cdn.cloubot.com/Rise/cover/${findItem?.code}.png`,
          book_name: findItem?.book_sub[0]?.title || findItem?.book_sub[0]?.short_title,
          book_id: findItem?.code,
        };
      });
    }
  }, [level, volume, bookData]);
  const bookContent = useMemo(() => {
    if (bookData) {
      const findItem = bookData.find((book) => book.level_no === level && book.volume_no === volume);

      return {
        book_name: findItem?.book_sub[0]?.title || findItem?.book_sub[0]?.short_title,
        book_image: findItem?.book_sub[0]?.cover_path || findItem?.book_sub[0]?.ebook_path,
        book_id: findItem?.code,
      };
    }
  }, [level, volume, bookData]);

  const showModal = (data) => {
    setIsModalVisible(true);
    setCurrentLesson(data);
    getBookLessonInfo({
      variables: {
        lessonCode: data.code,
      },
    });
  };

  const handleOk = useCallback(() => {
    setIsModalVisible(false);
    setCurrentLesson(null);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        key: 'No',
        title: '#',
        dataIndex: 'No',
        width: 60,
        align: 'center',
        render: (text, record, index) => {
          return index + 1;
        },
      },
      {
        key: 'book_image',
        title: 'Image',
        dataIndex: 'book_image',
        align: 'center',
        render: (text) => {
          return <img src={text} alt="book" style={{ width: '100px' }} />;
        },
      },
      {
        key: 'book_name',
        title: 'Book',
        dataIndex: 'book_name',
        align: 'center',
        render: (text) => {
          return bookContent.book_name;
        },
      },
      {
        key: 'unit_name',
        title: 'Unit',
        dataIndex: 'unit_name',
        align: 'center',
      },
      {
        key: 'title',
        title: 'Lesson',
        dataIndex: 'title',
        align: 'center',
        render: (text, record, index) => {
          return (
            <p
              onClick={() => {
                showModal({
                  ...record,
                  book_name: bookContent.book_name,
                  book_image: bookContent.book_image,
                  no: index + 1,
                });
              }}
              style={{ cursor: 'pointer', color: 'blue' }}
            >
              {text}
            </p>
          );
        },
      },
      {
        key: 'content_view',
        title: 'Content View',
        dataIndex: 'content_view',
        align: 'center',
        render: (text, record) => {
          return (
            <a href={record?.link_url} target="_blank" rel="noreferrer">
              <img src="/images/book/report/play-icon.png" alt="view" height={50} />
            </a>
          );
        },
      },
    ];
  }, [bookContent, culp_token]);

  return (
    <div>
      <Table columns={columns} dataSource={dailyArr} />
      {currentLesson && (
        <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleOk} size="large" title="Lesson Detail" footer={null} width={800}>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <TD style={{ background: 'lightgray' }}>#</TD>
                <TD>{currentLesson?.no}</TD>
                <TD style={{ background: 'lightgray' }}>TextBook ID</TD>
                <TD>{currentLesson?.book_id}</TD>
              </tr>
              <tr>
                <TD style={{ background: 'lightgray' }}>Textbook Image</TD>
                <TD>
                  <img src={currentLesson?.book_image} alt="book" style={{ width: '100px', height: '100px' }} />
                  <a href={currentLesson.link_url} target="_blank" rel="noreferrer">
                    <img src="/images/book/report/play-icon.png" alt="view" height={50} />
                  </a>
                </TD>
                <TD style={{ background: 'lightgray' }}>Category</TD>
                <TD>{currentLesson?.category}</TD>
              </tr>
              <tr>
                <TD style={{ background: 'lightgray' }}>Book Name</TD>
                <TD>{currentLesson?.book_name}</TD>
                <TD style={{ background: 'lightgray' }}>Unit Name</TD>
                <TD>{currentLesson?.unit_name}</TD>
              </tr>
              <tr>
                <TD style={{ background: 'lightgray' }}>Scope of Study</TD>
                <TD colSpan={3}>
                  {lessonStage?.map((stage, index) => (
                    <span key={index} style={{ marginRight: 10 }}>
                      {stage.no}. {stage.name} &nbsp;
                    </span>
                  ))}
                </TD>
              </tr>
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default DailyContent;
