import React, { useMemo, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Col, Tabs, Spin, Radio } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import { BOOK_LIST_BY_PREFIX_FOR_CONTENTS } from 'src/operations/queries/book';
import VolumeGroup from './VolumeGroup';
import DailyContent from './DailyContent';
import { useHistory } from 'react-router-dom';
import { classStoreData } from 'src/operations/store';
import { settings } from 'src/dummy/settings';

const { TabPane } = Tabs;

const ContentLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: #fff;
  flex-direction: column;
`;

const SubContentLayout = styled.div`
  display: block;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
`;

const Evine = () => {
  const defaultClass = useSelector(classStoreData);

  const prefix = settings[defaultClass] ? settings[defaultClass].prefix : null;
  const books = settings[defaultClass] ? settings[defaultClass].books : null;

  const [selectedPrefix, setSelectedPrefix] = useState(prefix);

  const [volumeNumber, setVolumeNumber] = useState(1);
  const { data, loading } = useQuery(BOOK_LIST_BY_PREFIX_FOR_CONTENTS, { variables: { prefix: selectedPrefix }, skip: !selectedPrefix });
  const levels = useMemo(() => {
    if (data?.bookListByPrefix) {
      console.log('data.bookListByPrefix', data.bookListByPrefix);
      return data.bookListByPrefix
        .map((book) => ({
          no: book.level_no,
          name: book.book_sub[0] ? book.book_sub[0]?.short_title || book.book_sub[0]?.title : book.level_name,
        }))
        .filter((level, key, arr) => key === arr.findIndex((item) => item.no === level.no));
    }
    return [];
  }, [data]);

  const handleTabChange = useCallback(() => {
    setVolumeNumber(1);
  }, []);
  const handleChangeVolume = useCallback((evt) => {
    const {
      target: { value },
    } = evt;
    setVolumeNumber(value);
  }, []);

  const handleBookChange = useCallback((evt) => {
    const {
      target: { value },
    } = evt;
    setSelectedPrefix(value);
  }, []);

  const history = useHistory();

  return (
    <Col justify="space-between" style={{ width: '100%', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
      <HeaderTitle level={4}>컨텐츠 확인</HeaderTitle>
      <ContentLayout className="contents-contentlayout">
        <Radio.Group value={selectedPrefix} style={{ padding: 10, width: '100%' }} onChange={handleBookChange}>
          {books &&
            books.map((book, key) => (
              <Radio.Button key={`radio-book-key${key}`} value={book}>
                {book}
              </Radio.Button>
            ))}
        </Radio.Group>
        <Tabs defaultActiveKey={0} type="card" size="large" onChange={handleTabChange} style={{ width: '100%' }}>
          {levels.map((levelItem, key) => (
            <TabPane tab={levelItem.name} key={levelItem.no}>
              <SubContentLayout className="contents-evine-inside">
                <VolumeGroup
                  levelItem={levelItem}
                  bookData={data.bookListByPrefix}
                  volumeNumber={volumeNumber}
                  onVolumeChange={handleChangeVolume}
                />
                <DailyContent level={levelItem.no} volume={volumeNumber} bookData={data.bookListByPrefix} prefix={prefix} />
              </SubContentLayout>
            </TabPane>
          ))}
        </Tabs>
      </ContentLayout>
    </Col>
  );
};

export default Evine;
