import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Col, Input, Row, DatePicker, Radio } from 'antd';
import { getStageColsRise } from 'src/utils/bookStage';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_CLASSS_LESSONS_FOR_CONTENT } from 'src/operations/queries/getClass';
import { Link } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import UseHistoryState from 'src/components/common/UseHistoryState';
import HomeworkResultMenuGroup from 'src/components/common/HomeworkResultMenuGroup';
import HomeworkResultScoreMenuGroupRise from 'src/components/common/HomeworkResultScoreMenuGroupRise';

const { RangePicker } = DatePicker;
const stageGroupColor = {
  v: '#ffce54',
  s: '#5d9cec',
  e: '#a0d468',
  reading: '#f52516',
  listening: '#f59f00',
  grammar: '#04a7f5',
  r: '#f52516',
  l: '#f59f00',
  g: '#04a7f5',
};

const StageGroups = {
  RP: [
    {
      stage_no: 1,
      name_abbr: 'intro',
      book_stage: [
        {
          name: 'intro',
          name_abbr: 'intro',
          no: 1,
          group_code: 'lesson',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 2,
      name_abbr: 'practice',
      book_stage: [
        {
          name: 'practice',
          name_abbr: 'practice',
          no: 2,
          group_code: 'lesson',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 3,
      name_abbr: 'match',
      book_stage: [
        {
          name: 'match',
          name_abbr: 'match',
          no: 3,
          group_code: 'lesson',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 4,
      name_abbr: 'talk',
      book_stage: [
        {
          name: 'talk',
          name_abbr: 'talk',
          no: 4,
          group_code: 'lesson',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 5,
      name_abbr: 'search',
      book_stage: [
        {
          name: 'search',
          name_abbr: 'search',
          no: 1,
          group_code: 'review',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 6,
      name_abbr: 'picture',
      book_stage: [
        {
          name: 'picture',
          name_abbr: 'picture',
          no: 2,
          group_code: 'review',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 7,
      name_abbr: 'outro',
      book_stage: [
        {
          name: 'outro',
          name_abbr: 'outro',
          no: 3,
          group_code: 'review',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
  ],
  'RR,RW,RL': [
    {
      stage_no: 1,
      name_abbr: 'pi',
      book_stage: [
        {
          name: 'Pronunciation',
          name_abbr: 'pi',
          no: 1,
          group_code: 'voca',
          category_name: 'pronunciation',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 2,
      name_abbr: 'wpd',
      book_stage: [
        {
          name: 'Drag and drop',
          name_abbr: 'wpd',
          no: 2,
          group_code: 'voca',
          category_name: 'word_practice',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 3,
      name_abbr: 'wpt',
      book_stage: [
        {
          name: 'Typing',
          name_abbr: 'wpt',
          no: 3,
          group_code: 'voca',
          category_name: 'word_practice',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 4,
      name_abbr: 'vs',
      book_stage: [
        {
          name: 'Speaking',
          name_abbr: 'vs',
          no: 4,
          group_code: 'voca',
          category_name: 'speaking',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 5,
      name_abbr: 'wrp',
      book_stage: [
        {
          name: 'Word Puzzle',
          name_abbr: 'wrp',
          no: 5,
          group_code: 'voca',
          category_name: 'word_review',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 6,
      name_abbr: 'wrm',
      book_stage: [
        {
          name: 'Memory Card',
          name_abbr: 'wrm',
          no: 6,
          group_code: 'voca',
          category_name: 'word_review',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 7,
      name_abbr: 'ksu',
      book_stage: [
        {
          name: 'Unscramble',
          name_abbr: 'ksu',
          no: 1,
          group_code: 'expr',
          category_name: 'sentence',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 8,
      name_abbr: 'ksq',
      book_stage: [
        {
          name: 'Choice',
          name_abbr: 'ksq',
          no: 2,
          group_code: 'expr',
          category_name: 'sentence',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 9,
      name_abbr: 'kst',
      book_stage: [
        {
          name: 'Sentence Typing',
          name_abbr: 'kst',
          no: 3,
          group_code: 'expr',
          category_name: 'sentence',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 10,
      name_abbr: 'exs',
      book_stage: [
        {
          name: 'Speaking',
          name_abbr: 'exs',
          no: 4,
          group_code: 'expr',
          category_name: 'speaking',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 11,
      name_abbr: 'ra',
      book_stage: [
        {
          name: 'Read Aloud',
          name_abbr: 'ra',
          no: 1,
          group_code: 'rc',
          category_name: 'read_aloud',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 12,
      name_abbr: 'quiz',
      book_stage: [
        {
          name: 'Comprehension',
          name_abbr: 'quiz',
          no: 2,
          group_code: 'rc',
          category_name: 'comprehension',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
  ],
};

const getPercentGrade = (total, score) => {
  if (total === 0 || total === null || score === null) {
    return 0;
  }
  return Math.round((100 * score) / total);
};

const PER_PAGE = 10;

const LearningContent = () => {
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      search_text: '',
      pageNumber: 0,
      study_date: [],
      state: 'all', //end
      content: 'RP',
    },
    'hw_cn_search',
  );
  const company_name = userInfoVar()?.company_name;
  const campus_idx = userInfoVar()?.campus_idx;
  const { data: dataClassLessons, loading } = useQuery(GET_CLASSS_LESSONS_FOR_CONTENT, {
    variables: {
      campus_idx: campus_idx,
      page: searchValue.pageNumber,
      search_text: searchValue.search_text,
      searchDate: searchValue.study_date,
      state: searchValue.state,
      content: searchValue.content,
    },
    skip: !campus_idx,
  });

  const classLessonsReal = useMemo(() => {
    if (dataClassLessons) {
      return dataClassLessons.classLessonsForContentResult?.map((item, key) => {
        const studentTotal = item?.class?.class_student?.length;

        let stage_group_code = item?.book_lesson?.stage_group_code?.split('')[0];
        let stageGroup = StageGroups[searchValue.content];
        let filterStageGroup = stageGroup.filter((stage) => {
          return item?.book_lesson?.stage_group_code === stage.book_stage[0].group_code;
        });

        let user_lessons = item?.user_lesson;
        let completeList = 0;
        let totalLesson = 0;
        let filteredUserStudy = [];
        if (user_lessons) {
          user_lessons.forEach((user_lesson) => {
            let user_study = user_lesson?.user_study;
            if (user_study) {
              let filtered = user_study.filter((item) => {
                return filterStageGroup.find((stage) => {
                  return item.stage_no === stage.stage_no;
                });
              });
              filteredUserStudy.push(...filtered);
              completeList += filtered.length;
              totalLesson += filterStageGroup.length;
            }
          });
        }

        return {
          No: PER_PAGE * searchValue.pageNumber + key + 1,
          key: `class-lesson-row${item.idx}-${item.lesson_code}`,
          class: item?.class?.name,
          bookInfo: item?.book_lesson?.book?.book_sub ? item?.book_lesson?.book?.book_sub[0]?.title : item?.book_lesson?.book_code,
          lessonCode: item.lesson_code,
          classIdx: item.class.idx,
          title: `${item?.book_lesson?.title || item.lesson_code}`,
          date: moment(item.study_date).format('YY/MM/DD'),
          org_date: item.study_date,
          studentTotal,
          user_lessons: item?.user_lesson,
          stage_group_code,
          stage_group_code_full: item?.book_lesson?.stage_group_code,
          completeList,
          totalLesson,
          filteredUserStudy,
          filterStageGroup,
        };
      });
    }
    return [];
  }, [dataClassLessons, searchValue]);

  const classList = useMemo(() => {
    if (classLessonsReal) {
      let index = 1;
      let classList = classLessonsReal.reduce((acc, cur) => {
        let idx = acc.findIndex((item) => item.class_idx === cur.classIdx);
        if (!acc.find((item) => item.class_idx === cur.classIdx)) {
          acc.push({ class: cur.class, class_idx: cur.classIdx, complete: 0, No: index++ });
          idx = acc.length - 1;
        }

        let stageGroup = cur.filterStageGroup;
        if (stageGroup) {
          for (let i = 0; i < stageGroup.length; i++) {
            let stage = stageGroup[i];
            let filtered = cur.filteredUserStudy.filter((item) => {
              return item.stage_no === stage.stage_no;
            });
            if (filtered.length === 0) {
              acc[idx][`stage${stage.name_abbr}`] = '--';
            } else {
              let total = 0;
              let correct = 0;
              filtered.forEach((item) => {
                total += item.exam_total;
                correct += item.exam_correct;
              });
              acc[idx][`stage${stage.name_abbr}`] = `${correct || 0}/${total || 0}(${getPercentGrade(total, correct)}%)`;
            }
          }
        }
        acc[idx].complete += cur.completeList;
        acc[idx].total = cur.totalLesson;
        acc[idx].filteredUserStudy = cur.filteredUserStudy;

        return acc;
      }, []);
      return classList;
    }
    return [];
  }, [classLessonsReal]);

  const handleDateChange = (_, date_string) => {
    if (
      date_string.length === 0 ||
      date_string[0] === 'Invalid date' ||
      date_string[1] === 'Invalid date' ||
      date_string[0] === '' ||
      date_string[1] === ''
    ) {
      setSearchValue((prev) => {
        return {
          ...prev,
          study_date: [],
          pageNumber: 0,
        };
      });
    } else {
      setSearchValue((prev) => {
        return {
          ...prev,
          study_date: date_string,
          pageNumber: 0,
        };
      });
    }
  };

  const handleNameSearch = (sv) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        search_text: sv,
        pageNumber: 0,
      };
    });
  };

  const handleTableChange = (pagination) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        pageNumber: pagination.current - 1,
      };
    });
  };

  const handleStateChange = (e) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        state: e.target.value,
        pageNumber: 0,
      };
    });
  };

  const handleContentChange = (e) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        content: e.target.value,
        pageNumber: 0,
      };
    });
  };
  const realColumns = useMemo(() => {
    const columns = [
      {
        key: 'No',
        title: 'No',
        dataIndex: 'No',
        width: 60,
        align: 'center',
      },
      {
        key: 'class',
        title: '반명',
        dataIndex: 'class',
        align: 'center',
        sorter: true,
      },
      {
        key: 'complete',
        title: '완료/총학생',
        dataIndex: 'complete',
        align: 'center',
      },
    ];

    const stageGroup = StageGroups[searchValue.content];
    if (stageGroup) {
      let added = getStageColsRise(stageGroup, stageGroup, true);
      added = [
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          align: 'center',
          render: (text, record) => {
            if (record.filteredUserStudy?.length === 0) {
              return '--';
            }
            let eachVal = record.filteredUserStudy ? record.filteredUserStudy : [];
            let total = 0;
            let correct = 0;
            eachVal.forEach((item) => {
              total += item.exam_total;
              correct += item.exam_correct;
            });

            if (total === 0) {
              return '--';
            }

            return `${correct}/${total}(${getPercentGrade(total, correct)}%)`;
          },
        },
        ...added,
        {
          title: 'Action',
          key: 'action',
          align: 'center',
          render: (text, record) => {
            if (searchValue.study_date.length === 0) {
              return (
                <Link to={`/learning-content-std?class_idx=${record.class_idx}&content=${searchValue.content}`}>
                  <Button type="primary">Detail</Button>
                </Link>
              );
            }
            return (
              <Link
                to={`/learning-content-std?class_idx=${record.class_idx}&study_start_date=${searchValue.study_date[0]}&study_end_date=${searchValue.study_date[1]}&content=${searchValue.content}`}
              >
                <Button type="primary">Detail</Button>
              </Link>
            );
          },
        },
      ];
      return columns.concat(added);
    }
    return columns;
  }, [searchValue.content, searchValue.study_date]);

  return (
    <>
      {company_name === 'rise' ? (
        <HomeworkResultScoreMenuGroupRise currentMenu="content" />
      ) : (
        <HomeworkResultMenuGroup currentMenu="content" />
      )}
      <div
        className="homeresult-dv"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 'auto',
        }}
      >
        <HeaderTitle level={4}>{company_name==='rise'?'반별 점수관리':'Homework Result'}</HeaderTitle>
        <div
          className="homeresult-selectfiled"
          style={{ display: 'inline-block', width: '85%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}
        >
          <Radio.Group value={searchValue.content} onChange={handleContentChange}>
            <Radio.Button value="RP">Phonics</Radio.Button>
            <Radio.Button value="RR,RW,RL">Reading</Radio.Button>
          </Radio.Group>
          &nbsp;&nbsp;
          <Radio.Group value={searchValue.state} onChange={handleStateChange}>
            <Radio.Button value="all">전체</Radio.Button>
            <Radio.Button value="ing">진행중</Radio.Button>
            <Radio.Button value="end">종료</Radio.Button>
          </Radio.Group>
          &nbsp;&nbsp;
          <RangePicker
            placeholder="출제날짜"
            initialValues={
              searchValue.study_date.length && [
                moment(searchValue.study_date[0], 'YYYY-MM-DD'),
                moment(searchValue.study_date[1], 'YYYY-MM-DD'),
              ]
            }
            value={
              searchValue.study_date.length && [
                moment(searchValue.study_date[0], 'YYYY-MM-DD'),
                moment(searchValue.study_date[1], 'YYYY-MM-DD'),
              ]
            }
            onChange={handleDateChange}
          />
          &nbsp;&nbsp;
          <Input.Search
            className="homeresult-inputfield"
            placeholder="반명 검색"
            type="text"
            defaultValue={searchValue.search_text}
            onSearch={handleNameSearch}
            style={{ width: '200px' }}
          />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="homeresult-halfwrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              {/* <Title level={5}>학생 List</Title> */}
              {/* <Button style={{ color: '#289428' }}>+ 숙제출제일정</Button> */}
            </div>

            <CustomTable
              dataSource={classList}
              columns={realColumns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                total: classList?.length,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default LearningContent;
