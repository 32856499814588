import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { useQuery } from '@apollo/client';
import { MY_MULTI_CAMPUS } from 'src/operations/queries/getUser';
import { useLoginTo } from 'src/utils/hooks';
import { userInfoVar } from 'src/apollo/cache';

const SwitchAccountButton = () => {
  const { data } = useQuery(MY_MULTI_CAMPUS, {
    fetchPolicy: 'no-cache',
    skip: userInfoVar() && userInfoVar().external_id === null,
  });
  const loginTo = useLoginTo(true);

  const swithCampus = useMemo(() => {
    return data?.myMultiCampusList || [];
  }, [data]);

  console.log(userInfoVar());

  const handleChangeCLick = (id) => {
    loginTo(id);
  };

  return (
    <>
      {swithCampus?.length > 0 && <Menu.Divider />}
      {swithCampus?.length > 0 && (
        <Menu.SubMenu
          key="switch"
          title={
            <>
              <img src="/images/menu-icon/c-cloud.png" alt="" style={{ width: '25px', height: '25px' }} />
              &nbsp;Switch Campus
            </>
          }
        >
          {swithCampus.map((user) => (
            <Menu.Item key={user.id} onClick={() => handleChangeCLick(user.id)}>
              {user.campus?.name}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      )}
    </>
  );
};

export default SwitchAccountButton;
