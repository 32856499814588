import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Select, DatePicker, Button, Input, Card } from 'antd';
import styled from 'styled-components';

import { userInfoVar } from 'src/apollo/cache';
import { MAX_SEARCH_INTERVAL_DAYS, DATE_SEARCH_OPTIONS } from 'src/constants/common';
import { BOOK_RETURN_STATS, DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_DASHBOARD } from 'src/constants/common';
import { getYearMonthArray } from 'src/utils';
import CustomTable from 'src/components/common/CustomTable';
import { GET_FC_BOOK_ORDER_AND_RETURN_LIST, GET_FC_BOOK_ORDER_AND_RETURN_LIST_BY_DURATION } from 'src/operations/queries/dashboard';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcel } from 'src/utils/index';

const STATISTIC_KINDS = [
  { label: '전체', value: '' },
  { label: '주문접수', value: '주문접수' },
  { label: '출고준비', value: '출고준비' },
  { label: '출고완료', value: '출고완료' },
  { label: '반품접수', value: '반품접수' },
  { label: '반품완료', value: '반품완료' },
];

const StatisCard = styled(Card)`
  text-align: center;
  border: 1px solid #aaa;
  .ant-card-head-title {
    font-weight: bold;
  }
`;

const months = getYearMonthArray('2021-11');

const { RangePicker } = DatePicker;
const { Option } = Select;

const SEARCH_OPTIONS = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '지사',
    value: 'branch',
  },
  {
    label: '학교',
    value: 'school',
  },
  {
    label: '강사',
    value: 'name',
  },
];
const DEFAULT_DATE_RANGE = [moment().subtract(1, 'months'), moment()];
const BOX_HEAD_STYLE = { textAlign: 'center', backgroundColor: '#EDF3FB' };

const isExistItem = (sv, so, item) => {
  const { campus_name, branch, buyer_name } = item;
  if (sv) {
    switch (so) {
      case 'branch':
        return branch.includes(sv);
      case 'school':
        return campus_name.includes(sv);
      case 'name':
        return buyer_name.includes(sv);
      default:
        return branch.includes(sv) || campus_name.includes(sv) || buyer_name.includes(sv);
    }
  }
  return true;
};

const DashBoardAfterSchool = ({ companyName }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [statisKind, setStatisKind] = useState();
  const [dateSearchOption, setDateSearchOption] = useState(DATE_SEARCH_OPTIONS[0].value);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);

  const company_idx = userInfoVar()?.campus?.company?.idx;
  const { data, loading } = useQuery(
    dateSearchOption === 0 ? GET_FC_BOOK_ORDER_AND_RETURN_LIST : GET_FC_BOOK_ORDER_AND_RETURN_LIST_BY_DURATION,
    {
      variables:
        dateSearchOption === 0
          ? { ym: selectedMonth, company_idx }
          : { start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD'), company_idx },
      skip: !company_idx,
      fetchPolicy: 'no-cache',
    },
  );
  const columns =
    companyName === 'englishtap'
      ? [
          {
            title: '지사',
            dataIndex: 'branch',
            key: 'branch',
          },
          {
            title: '학원',
            dataIndex: 'campus_name',
            key: 'campus_name',
          },
          {
            title: '강사',
            dataIndex: 'buyer_name',
            key: 'buyer_name',
          },
          {
            title: '신청일',
            dataIndex: 'idate',
            key: 'idate',
            width: '100px',
          },
          {
            title: '교재',
            dataIndex: 'product_name',
            key: 'product_name',
          },
          {
            title: '권수',
            dataIndex: 'order_student_count',
            key: 'order_student_count',
          },

          {
            title: '구분',
            dataIndex: 'kind',
            key: 'kind',
          },
          {
            title: '처리일',
            dataIndex: 'checkdate',
            key: 'checkdate',
            width: '100px',
          },
        ]
      : [
          {
            title: '지사',
            dataIndex: 'branch',
            key: 'branch',
          },
          {
            title: '학교',
            dataIndex: 'campus_name',
            key: 'campus_name',
          },
          {
            title: '강사',
            dataIndex: 'buyer_name',
            key: 'buyer_name',
          },
          {
            title: '신청일',
            dataIndex: 'idate',
            key: 'idate',
            width: '100px',
          },
          {
            title: '교재',
            dataIndex: 'product_name',
            key: 'product_name',
          },
          {
            title: '학생용',
            dataIndex: 'order_student_count',
            key: 'order_student_count',
          },
          {
            title: '교사용',
            dataIndex: 'order_teacher_count',
            key: 'order_teacher_count',
          },
          {
            title: '구분',
            dataIndex: 'kind',
            key: 'kind',
          },
          {
            title: '처리일',
            dataIndex: 'checkdate',
            key: 'checkdate',
            width: '100px',
          },
        ];

  const dataSource = useMemo(() => {
    const orderList = data?.MyFCOrderList || data?.MyFCOrderListByDuration;
    const returnList = data?.getAdminBookReturnList || data?.getAdminBookReturnListByDuration;
    const orderList2 = orderList
      ? orderList.reduce((acc, cur) => {
          const list = cur.order_list.reduce((acc2, cur2, index) => {
            const foundindex = acc2.findIndex((ele) => {
              return ele.product_code === cur2.product_code;
            });
            if (foundindex !== -1) {
              if (companyName === 'englishtap') {
                acc2[foundindex].order_student_count += cur2.product_name ? cur2.quantity : 0;
              } else {
                acc2[foundindex].order_student_count += cur2.product_name.includes('학생용') ? cur2.quantity : 0;
                acc2[foundindex].order_teacher_count += cur2.product_name.includes('교사용') ? cur2.quantity : 0;
                return [...acc2];
              }
            }
            const item = {
              key: `order-list-${cur.idx}-${index}`,
              statis_index: `order${cur.idx}`,
              campus_name: cur?.campus?.name,
              product_name: companyName === 'englishtap' ? cur2.product_name : cur2.product_name.replace(/학생용|교사용/gi, ''),
              product_code: cur2.product_code,
              branch: cur?.campus?.campus?.name,
              idate: moment(cur?.idate).format('YYYY-MM-DD'),
              //buyer_name: cur?.order_payment[0]?.buyer_name,
              buyer_name: cur?.user?.name,
              order_student_count: (companyName === 'englishtap' ? cur2.product_name : cur2.product_name.includes('학생용'))
                ? cur2.quantity
                : 0,
              order_teacher_count: cur2.product_name.includes('교사용') ? cur2.quantity : 0,
              kind: DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_DASHBOARD[cur?.order_payment[0]?.delivery_status || 0],
              checkdate: cur?.order_payment[0]?.invoice_check_date
                ? moment(cur?.order_payment[0]?.invoice_check_date).format('YYYY-MM-DD')
                : '-',
            };
            return [...acc2, item];
          }, []);
          return [...acc, ...list];
        }, [])
      : [];
    const returnList2 = returnList
      ? returnList.reduce((acc, cur) => {
          const list = cur.book_return_list.reduce((acc2, cur2, index) => {
            const foundindex = acc2.findIndex((ele) => {
              return ele.product_code === cur2.product_code;
            });
            if (foundindex !== -1) {
              acc2[foundindex].order_student_count -= cur2.product_name.includes('학생용') ? cur2.quantity : 0;
              acc2[foundindex].order_teacher_count -= cur2.product_name.includes('교사용') ? cur2.quantity : 0;
              return [...acc2];
            }
            const item = {
              key: `return-list-${cur.idx}-${index}`,
              statis_index: `return${cur.idx}`,
              campus_name: cur?.campus?.name,
              product_name: cur2.product_name.replace(/학생용|교사용/gi, ''),
              product_code: cur2.product_code,
              branch: cur?.campus?.campus?.name,
              idate: moment(cur?.idate).format('YYYY-MM-DD'),
              buyer_name: cur?.user?.name,
              order_student_count: cur2.product_name.includes('학생용') ? cur2.quantity * -1 : 0,
              order_teacher_count: cur2.product_name.includes('교사용') ? cur2.quantity * -1 : 0,
              kind: BOOK_RETURN_STATS[cur?.stat || 0],
              checkdate: cur?.check_date ? moment(cur?.check_date).format('YYYY-MM-DD') : '-',
            };
            return [...acc2, item];
          }, []);
          return [...acc, ...list];
        }, [])
      : [];

    return orderList2
      .concat(returnList2)
      .filter((item) => isExistItem(searchValue, searchOption, item))
      .filter((item2) => {
        if (statisKind && statisKind !== '') {
          return item2.kind === statisKind;
        }
        return true;
      })
      .sort((a, b) => {
        if (a.idate === b.idate) {
          return 0;
        }
        return a.idate < b.idate ? 1 : -1;
      });
  }, [data, searchOption, searchValue, statisKind]);

  const boxCountList = useMemo(() => {
    const studentBookCount = dataSource?.reduce((acc, { order_student_count }) => {
      return acc + order_student_count;
    }, 0);
    const teacherBookCount = dataSource?.reduce((acc, { order_teacher_count }) => {
      return acc + order_teacher_count;
    }, 0);
    const { order1, order2, order3, order4, order5 } = dataSource?.reduce((acc, { kind, statis_index }) => {
      if (acc?.st_index && acc.st_index === statis_index) {
        return { ...acc, st_index: statis_index };
      }
      switch (kind) {
        case '주문접수':
          const o1 = acc?.order1 || 0;
          return { ...acc, order1: o1 + 1, st_index: statis_index };
        case '출고준비':
          const o2 = acc?.order2 || 0;
          return { ...acc, order2: o2 + 1, st_index: statis_index };
        case '출고완료':
          const o3 = acc?.order3 || 0;
          return { ...acc, order3: o3 + 1, st_index: statis_index };
        case '반품접수':
          const o4 = acc?.order4 || 0;
          return { ...acc, order4: o4 + 1, st_index: statis_index };
        case '반품완료':
          const o5 = acc?.order5 || 0;
          return { ...acc, order5: o5 + 1, st_index: statis_index };
        default:
          break;
      }
      return { ...acc, st_index: statis_index };
    }, {});

    return companyName === 'englishtap'
      ? [
          {
            title: (
              <>
                주문접수
                <br />
                &nbsp;
              </>
            ),
            count: `${order1 || 0}`,
          },
          {
            title: (
              <>
                출고준비
                <br />
                &nbsp;
              </>
            ),
            count: `${order2 || 0}`,
          },
          {
            title: (
              <>
                출고완료
                <br />
                &nbsp;
              </>
            ),
            count: `${order3 || 0}`,
          },
          {
            title: (
              <>
                교재수량
                <br />
                &nbsp;
              </>
            ),
            count: `${studentBookCount}`,
          },
        ]
      : [
          {
            title: (
              <>
                주문접수
                <br />
                &nbsp;
              </>
            ),
            count: `${order1 || 0}`,
          },
          {
            title: (
              <>
                출고준비
                <br />
                &nbsp;
              </>
            ),
            count: `${order2 || 0}`,
          },
          {
            title: (
              <>
                출고완료
                <br />
                &nbsp;
              </>
            ),
            count: `${order3 || 0}`,
          },
          {
            title: (
              <>
                반품접수
                <br />
                &nbsp;
              </>
            ),
            count: `${order4 || 0}`,
          },
          {
            title: (
              <>
                반품완료
                <br />
                &nbsp;
              </>
            ),
            count: `${order5 || 0}`,
          },
          {
            title: (
              <>
                교재수량
                <br />
                (학생용)
              </>
            ),
            count: `${studentBookCount}`,
          },
          {
            title: (
              <>
                교재수량
                <br />
                (교사용)
              </>
            ),
            count: `${teacherBookCount}`,
          },
        ];
  }, [dataSource]);

  const bookCountList = useMemo(() => {
    if (companyName === 'englishtap') {
      const { ktp, ltr, vt, et, ltrs } = dataSource?.reduce((acc, cur) => {
        if (cur.product_code.includes('VT-L')) {
          if (acc.vt === undefined) {
            acc.vt = 0;
          }
          acc.vt += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('KTP-L')) {
          if (acc.ktp === undefined) {
            acc.ktp = 0;
          }
          acc.ktp += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('LTR-L')) {
          if (acc.ltr === undefined) {
            acc.ltr = 0;
          }
          acc.ltr += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('ET-L')) {
          if (acc.et === undefined) {
            acc.et = 0;
          }
          acc.et += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('LTRS-L')) {
          if (acc.ltrs === undefined) {
            acc.ltrs = 0;
          }
          acc.ltrs += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        return { ...acc };
      }, {});
      return [
        {
          title: (
            <>
              English
              <br />
              Tap &nbsp;
            </>
          ),
          count: `${et || 0}`,
        },
        {
          title: (
            <>
              Lime Tree
              <br />
              Readers
            </>
          ),
          count: `${ltr || 0}`,
        },
        {
          title: (
            <>
              Lime Tree
              <br />
              Readers Set &nbsp;
            </>
          ),
          count: `${ltrs || 0}`,
        },
        {
          title: (
            <>
              Kidstap
              <br />
              Phonics
            </>
          ),
          count: `${ktp || 0}`,
        },

        {
          title: (
            <>
              Voca Tap
              <br />
              &nbsp;
            </>
          ),
          count: `${vt || 0}`,
        },
      ];
    } else {
      const { coco, ktp, ltr, sm, sk, tt, gt, vt, cca, hph, ltrs, tcl, et, gh } = dataSource?.reduce((acc, cur) => {
        if (cur.product_code.includes('SM-L0')) {
          if (acc.coco === undefined) {
            acc.coco = 0;
          }
          acc.coco += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('SM-L')) {
          if (acc.sm === undefined) {
            acc.sm = 0;
          }
          acc.sm += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('SK-L0')) {
          if (acc.coco === undefined) {
            acc.coco = 0;
          }
          acc.coco += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('SK-L')) {
          if (acc.sk === undefined) {
            acc.sk = 0;
          }
          acc.sk += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('TT-L')) {
          if (acc.tt === undefined) {
            acc.tt = 0;
          }
          acc.tt += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('GT-L')) {
          if (acc.gt === undefined) {
            acc.gt = 0;
          }
          acc.gt += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('VT-L')) {
          if (acc.vt === undefined) {
            acc.vt = 0;
          }
          acc.vt += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('KTP-L')) {
          if (acc.ktp === undefined) {
            acc.ktp = 0;
          }
          acc.ktp += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('LTR-L')) {
          if (acc.ltr === undefined) {
            acc.ltr = 0;
          }
          acc.ltr += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('CCA-L')) {
          if (acc.cca === undefined) {
            acc.cca = 0;
          }
          acc.cca += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('ET-L')) {
          if (acc.et === undefined) {
            acc.et = 0;
          }
          acc.et += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('HPH-L0')) {
          if (acc.hph === undefined) {
            acc.hph = 0;
          }
          acc.hph += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('LTRS-L')) {
          if (acc.ltrs === undefined) {
            acc.ltrs = 0;
          }
          acc.ltrs += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('TCL-L')) {
          if (acc.tcl === undefined) {
            acc.tcl = 0;
          }
          acc.tcl += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        if (cur.product_code.includes('GH-L')) {
          if (acc.gh === undefined) {
            acc.gh = 0;
          }
          acc.gh += cur.order_student_count + cur.order_teacher_count;
          return { ...acc };
        }

        return { ...acc };
      }, {});
      return [
        {
          title: (
            <>
              Coco
              <br />
              Phonics
            </>
          ),
          count: `${coco || 0}`,
        },
        {
          title: (
            <>
              Kidstap
              <br />
              Phonics
            </>
          ),
          count: `${ktp || 0}`,
        },
        {
          title: (
            <>
              Lime Tree
              <br />
              Readers
            </>
          ),
          count: `${ltr || 0}`,
        },
        {
          title:
            companyName === 'smart-eclass' ? (
              <>
                Smart E
                <br />
                Class
              </>
            ) : (
              <>
                Sneakers
                <br />
                &nbsp;
              </>
            ),
          count: `${companyName === 'smart-eclass' ? (sm ? sm : 0) : sk ? sk : 0}`,
        },
        {
          title: (
            <>
              Talking
              <br />
              Tap
            </>
          ),
          count: `${tt || 0}`,
        },
        {
          title: (
            <>
              Grammar
              <br />
              Tap
            </>
          ),
          count: `${gt || 0}`,
        },
        {
          title: (
            <>
              Voca Tap
              <br />
              &nbsp;
            </>
          ),
          count: `${vt || 0}`,
        },
        {
          title: (
            <>
              Coco
              <br />
              Activity &nbsp;
            </>
          ),
          count: `${cca || 0}`,
        },
        {
          title: (
            <>
              Hello
              <br />
              Phonics &nbsp;
            </>
          ),
          count: `${hph || 0}`,
        },
        {
          title: (
            <>
              Lime Tree
              <br />
              Readers Set &nbsp;
            </>
          ),
          count: `${ltrs || 0}`,
        },
        {
          title: (
            <>
              The Class
              <br />
              &nbsp;
            </>
          ),
          count: `${tcl || 0}`,
        },
        {
          title: (
            <>
              English
              <br />
              Tap &nbsp;
            </>
          ),
          count: `${et || 0}`,
        },
        {
          title: (
            <>
              Grammar
              <br />
              House &nbsp;
            </>
          ),
          count: `${gh || 0}`,
        },
      ];
    }
  }, [dataSource, companyName]);

  function handleDisabledDate(current) {
    return current && current > moment().endOf('day');
  }

  function handleStatisDownload() {
    ExportExcel(columns, dataSource, `statis-${moment().format('YYYY-MM-DD')}`);
  }
  function handleFetchToday() {
    setDateSearchOption(DATE_SEARCH_OPTIONS[1].value);
    setDateRange([DEFAULT_DATE_RANGE[1], DEFAULT_DATE_RANGE[1]]);
  }
  return (
    <>
      <Row gutter={[24, 16]}>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Select
            style={{ marginRight: '5px' }}
            placeholder="검색"
            defaultValue=""
            value={searchOption}
            onChange={setSearchOption}
            options={SEARCH_OPTIONS}
          />
          <Input.Search style={{ width: '150px', marginRight: 10 }} placeholder={`search`} maxLength={20} onSearch={setSearchValue} />
          <Select placeholder="구분" options={STATISTIC_KINDS} value={statisKind} onChange={setStatisKind} style={{ width: 100 }} />
          <Select placeholder="선택" value={dateSearchOption} onChange={setDateSearchOption}>
            {DATE_SEARCH_OPTIONS.map((item) => (
              <Option key={`search-month-option${item.value}`} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
          {dateSearchOption === 0 ? (
            <Select placeholder="선택" value={selectedMonth} onChange={setSelectedMonth}>
              {months &&
                months.map((month, key) => (
                  <Option key={`search-month-option${key}`} value={month}>
                    {month}
                  </Option>
                ))}
            </Select>
          ) : (
            <RangePicker
              value={dateRange}
              allowClear={false}
              disabledDate={handleDisabledDate}
              onChange={(dates) => {
                const [start, end] = dates;
                const intervalDays = end.diff(start, 'days');
                if (intervalDays > MAX_SEARCH_INTERVAL_DAYS) {
                  alert('검색 기간은 최대 3개월입니다.');
                  // return false;
                }else
                setDateRange(dates);
              }}
            />
          )}
          &nbsp;
          <Button onClick={handleFetchToday}>오늘</Button>
          &nbsp; &nbsp;
          <img src={imageexcel} alt="download" style={{ width: 25, height: 25, cursor: 'pointer' }} onClick={handleStatisDownload} />
        </Col>
      </Row>
      <Row gutter={[24, 16]} style={{ marginTop: 10 }}>
        {boxCountList &&
          boxCountList.map((item, key) => (
            <Col key={`box-count-key${key}`} span={3} xs={24} sm={12} md={6} lg={3}>
              <StatisCard size="small" title={item.title} headStyle={BOX_HEAD_STYLE}>
                <p>{item.count} 건</p>
              </StatisCard>
            </Col>
          ))}
      </Row>
      <Row gutter={[24, 16]} style={{ marginTop: 10 }}>
        {bookCountList &&
          bookCountList.map((item, key) => (
            <>
              <Col key={`box-count2-key${key}`} span={3} xs={24} sm={12} md={6} lg={3}>
                <StatisCard size="small" title={item.title} headStyle={BOX_HEAD_STYLE}>
                  <p>{item.count}</p>
                </StatisCard>
              </Col>
              {key === 6 ? <Col key={`box-count2-dummy`} style={{ opacity: 0 }} span={3} xs={24} sm={12} md={6} lg={3}></Col> : null}
            </>
          ))}
      </Row>
      <Row gutter={[24, 16]} style={{ marginTop: 10 }}>
        <Col span={24}>
          <CustomTable
            loading={loading}
            className="orderadmin-table-table"
            pagination={{ pageSize: 12, position: ['bottomCenter'] }}
            dataSource={dataSource}
            columns={columns}
            size="small"
            color="#edf3fb"
            scroll={{ y: 'calc(100vh - 254px)' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default DashBoardAfterSchool;
