import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Typography, Input, Button, Modal } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import TeacherModal from 'src/components/Modal/TeacherModal';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';

import { userInfoVar, userTypVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { phoneNumberCheck } from 'src/utils';

// import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';
import ClassAndMemberMenuGroup from 'src/components/common/ClassAndMemberMenuGroup';
import ClassAndMemberMenuGroupRise from 'src/components/common/ClassAndMemberMenuGroupRise';
// import { isAfterSchool } from 'src/utils';
import { useLoginTo } from 'src/utils/hooks';
const { Title, Text } = Typography;
const { confirm } = Modal;

const Teacher = () => {
  const companyName = useSelector(classStoreData);
  const loginTo = useLoginTo(false);

  // const companyName = useSelector(classStoreData);
  const [visible, setVisible] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [popupMode, setPopupMode] = useState('');
  const [filterTable, setFilterTable] = useState(null);
  const [searchValue, setSeachValue] = useState('');
  const [checkRowList, setcheckRowList] = useState([]);

  const {
    data,
    loading: getUserLoading,
    refetch,
  } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      type: companyName !== 'educo' ? userTypVar().teacher : `${userTypVar().teacher},${userTypVar().manager},${userTypVar().employee}`,
    },
  });

  const [deleteUser] = useMutation(mutations.deleteUser.DELETE_USER, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (data?.getUsers) {
      setTeachers(
        data.getUsers.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            No: index + 1,
            name: cur.name,
            id: cur.id,
            view_id: cur.view_id,
            phone: cur.phone,
            email: cur.email,
            type: cur.type,
            loginTo,
          };

          return [...acc, obj];
        }, []),
      );
    }
  }, [data]);

  const showModal = (mode = 'create') => {
    setPopupMode(mode);
    setVisible(true);
  };

  const handleTeacherModalCancel = () => {
    setVisible(false);
  };

  const handleSearch = (value) => {
    setSeachValue(value);
    const filterTable = teachers.filter((teacher) => {
      return Object.keys(teacher).some((field) => String(teacher[field]).toLowerCase().includes(value.toLowerCase()));
    });

    setFilterTable(filterTable);
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
    getCheckboxProps: (record) => ({
      disabled: record.type !== '1',
    }),
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              user_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => {
        return <Link to={`/teachers/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: 'ID',
      dataIndex: 'view_id',
      key: 'id',
      align: 'center',
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (text, record) => {
        return <Link to={`/teachers/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (text, record) => phoneNumberCheck(text),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    ...(companyName === 'educo'
      ? [
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (text, record) => {
              return text === '1' ? '코치' : userInfoVar()?.campus?.manager?.idx === record.key ? '센터장' : '부센터장';
            },
          },
        ]
      : []),
    {
      title: '상세보기',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button>
          <Link to={`/teachers/${text.key}`}>View </Link>
        </Button>
      ),
    },
    {
      title: '로그인',
      dataIndex: 'login',
      key: 'login',
      align: 'center',
      width: '100px',
      render: (_, record) => {
        return (
          <CustomButton
            type="primary"
            size="small"
            onClick={() => {
              //loginTo(record.id, record.client, record.loginUser);
              record.loginTo(record.id);
            }}
          >
            로그인
          </CustomButton>
        );
      },
    },
  ];

  return (
    <>
      {companyName==='rise'?<ClassAndMemberMenuGroupRise currentMenu="teacher" />:<ClassAndMemberMenuGroup currentMenu="teacher" />}
      <HeaderTitle level={4}>강사 관리</HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom-teacher"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                강사 List
              </Title>
              <div className="header-classroom-search">
                <Input.Search
                  className="inputsearch-classroom"
                  placeholder="검색어를 입력해 주세요"
                  allowClear
                  style={{ width: 250, marginRight: 10 }}
                  enterButton
                  onSearch={handleSearch}
                  onChange={onSeachChange}
                  value={searchValue}
                />
                <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                  <RedoOutlined />
                </CustomButton>
                {!userInfoVar()?.campus?.external_id && (
                  <CustomButton style={{ marginRight: 10 }} onClick={() => showModal('create')}>
                    <PlusOutlined style={{ color: '#52c419' }} />
                    <Text type={'success'}>등록</Text>
                  </CustomButton>
                )}

                <CustomButton
                  danger
                  style={{
                    marginRight: 10,
                  }}
                  onClick={deleteConfirm}
                >
                  삭제
                </CustomButton>
              </div>
            </div>

            <CustomTable
              className="classroom-table-table"
              loading={getUserLoading}
              dataSource={(filterTable === null ? teachers : filterTable)?.reverse()}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: 12 }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {}, // click row
                };
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <TeacherModal popupMode={popupMode} handleCancel={handleTeacherModalCancel} visible={visible} refetch={refetch} />}
    </>
  );
};

export default Teacher;
