import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Badge, DatePicker, Radio } from 'antd';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_CLASSS_LESSONS_FOR_HOMEWORK } from 'src/operations/queries/getClass';
import { Link } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import UseHistoryState from 'src/components/common/UseHistoryState';
import HomeworkResultMenuGroupRise from 'src/components/common/HomeworkResultMenuGroupRise';
import HomeworkResultMenuGroup from 'src/components/common/HomeworkResultMenuGroup';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const stageGroupColor = {
  v: '#ffce54',
  s: '#5d9cec',
  e: '#a0d468',
  reading: '#f52516',
  listening: '#f59f00',
  grammar: '#04a7f5',
  r: '#f52516',
  l: '#f59f00',
  g: '#04a7f5',
};



const PER_PAGE = 10;

const LearningCommon = () => {
  const { selectedClassIdx, onClassChange } = useCampusClass();
const companyName = useSelector(classStoreData);
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      class_idx: 0,
      search_text: '',
      pageNumber: 0,
      study_date: '',
      state: 'ing', //end
    },
    'hw_search',
  );
  const campus_idx = userInfoVar()?.campus_idx;
  const { data: dataClassLessons, loading } = useQuery(GET_CLASSS_LESSONS_FOR_HOMEWORK, {
    variables: {
      skip: !campus_idx,
      campus_idx: campus_idx,
      page: searchValue.pageNumber,
      class_idx: searchValue.class_idx,
      search_text: searchValue.search_text,
      searchDate: searchValue.study_date,
      state: searchValue.state,
    },
  });

  const classLessonsReal = useMemo(() => {
    if (dataClassLessons) {
      return dataClassLessons.classLessonsForHomworkResult?.class_lessons.map((item, key) => {
        const studentTotal = item?.class?.class_student?.length;

        const complete = item?.class?.user_lesson
          ?.filter((item2) => item2.lesson_code === item.lesson_code)
          .filter((item3) => item3.stat === '1').length;

        console.log('item', item);
        let stage_group_code = item?.book_lesson?.stage_group_code?.split('')[0];
        //creotown 예외처리.. 헐..
        if (item.lesson_code.startsWith('CR-L2') && item.lesson_code.endsWith('D4')) {
          stage_group_code = 'g';
        }
        return {
          No: PER_PAGE * searchValue.pageNumber + key + 1,
          key: `class-lesson-row${item.idx}-${item.lesson_code}`,
          class: item?.class?.name,
          bookInfo: item?.book_lesson?.book?.book_sub ? item?.book_lesson?.book?.book_sub[0]?.title : item?.book_lesson?.book_code,
          lessonCode: item.lesson_code,
          classIdx: item.class.idx,
          title: `${item?.book_lesson?.title || item.lesson_code}`,
          date: moment(item.study_date).format('YY/MM/DD'),
          org_date: item.study_date,
          complete: `${complete}/${studentTotal}`,
          stage_group_code,
          stage_group_code_full: item?.book_lesson?.stage_group_code,
        };
      });
    }
    return [];
  }, [dataClassLessons, searchValue]);

  useEffect(() => {
    onClassChange(searchValue.class_idx);
    // eslint-disable-next-line
  }, [searchValue.class_idx]);

  const handleClassChange = (idx) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        class_idx: idx,
        pageNumber: 0,
      };
    });
  };

  const handleDateChange = (_, date_string) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        study_date: date_string,
        pageNumber: 0,
      };
    });
  };

  const handleNameSearch = (sv) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        search_text: sv,
        pageNumber: 0,
      };
    });
  };

  const handleTableChange = (pagination) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        pageNumber: pagination.current - 1,
      };
    });
  };

  const handleStateChange = (e) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        state: e.target.value,
        pageNumber: 0,
      };
    });
  };
  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '출제날짜',
      dataIndex: 'date',
      align: 'date',
    },
    {
      key: 'class',
      title: '반명',
      dataIndex: 'class',
      align: 'center',
    },
    {
      key: 'bookInfo',
      title: '교재정보',
      dataIndex: 'bookInfo',
      align: 'center',
    },
    {
      key: 'title',
      title: companyName==='rise'?'컨텐츠 정보':'Lesson',
      dataIndex: 'title',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <Badge
              className="site-badge-count-109"
              count={record?.stage_group_code?.toUpperCase()}
              style={{
                backgroundColor: stageGroupColor[record?.stage_group_code] ? stageGroupColor[record?.stage_group_code] : '#ff0000',
              }}
            />
            <span>&nbsp;{text}</span>
          </>
        );
      },
    },
  
    {
      key: 'complete',
      title: '완료/총학생',
      dataIndex: 'complete',
      align: 'center',
    },
    {
      key: 'view',
      title: '확인',
      dataIndex: 'view',
      align: 'center',
      render: (_, { class: className, classIdx, lessonCode, title: lessonTitle, org_date }) => {
        return (
          <Link to={{ pathname: `/learning/${classIdx}/${lessonCode}`, state: { className, lessonTitle, org_date } }}>
            <Button>View</Button>
          </Link>
        );
      },
    },
  ];
  return (
    <>
      {companyName==='rise'?<HomeworkResultMenuGroupRise currentMenu="date" />:<HomeworkResultMenuGroup currentMenu="date" />}
      <div
        className="homeresult-dv"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 'auto',
        }}
      >
        <HeaderTitle level={4}>
        {companyName==='rise'?'학생 점수관리':'Homework Result'}
          </HeaderTitle>
        <div
          className="homeresult-selectfiled"
          style={{ display: 'inline-block', width: '50%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}
        >
          <Radio.Group value={searchValue.state} onChange={handleStateChange}>
            <Radio.Button value="ing">진행중</Radio.Button>
            <Radio.Button value="end">종료</Radio.Button>
          </Radio.Group>
          <ClassSelectBox
            onClassChange={handleClassChange}
            selectedClassIdx={selectedClassIdx}
            campus_idx={campus_idx}
            style={{ width: '250px' }}
            isOnlyLatest={false}
          />
          <DatePicker
            placeholder="출제날짜"
            initialValues={searchValue.study_date && moment(searchValue.study_date, 'YYYY-MM-DD')}
            value={searchValue.study_date && moment(searchValue.study_date, 'YYYY-MM-DD')}
            onChange={handleDateChange}
          />
          &nbsp;&nbsp;
          <Input.Search
            className="homeresult-inputfield"
            placeholder="반명 검색"
            type="text"
            defaultValue={searchValue.search_text}
            onSearch={handleNameSearch}
            style={{ width: '200px' }}
          />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="homeresult-halfwrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              {/* <Title level={5}>학생 List</Title> */}
              {/* <Button style={{ color: '#289428' }}>+ 숙제출제일정</Button> */}
            </div>

            <CustomTable
              dataSource={classLessonsReal}
              columns={columns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                current: searchValue.pageNumber + 1,
                total: dataClassLessons?.classLessonsForHomworkResult?.total || 0,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default LearningCommon;
