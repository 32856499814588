export const settings = {
  default: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_listening1: '#a0d468',
      homework_listening2: '#a0d468',
      homework_reading1: '#a0d468',
      homework_reading2: '#a0d468',
      homework_grammar1: '#a0d468',
      homework_grammar2: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_daily_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  semtle: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_listening1: '#a0d468',
      homework_listening2: '#a0d468',
      homework_reading1: '#a0d468',
      homework_reading2: '#a0d468',
      homework_grammar1: '#a0d468',
      homework_grammar2: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_daily_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  semtlenuri3: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_listening1: '#a0d468',
      homework_listening2: '#a0d468',
      homework_reading1: '#a0d468',
      homework_reading2: '#a0d468',
      homework_grammar1: '#a0d468',
      homework_grammar2: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_daily_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  semtlenuri4: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_listening1: '#a0d468',
      homework_listening2: '#a0d468',
      homework_reading1: '#a0d468',
      homework_reading2: '#a0d468',
      homework_grammar1: '#a0d468',
      homework_grammar2: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_daily_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  epublic: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
    },
    showCountButtons: false,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  cloubot: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  cleveredu: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  cleveredu2: {
    prefix: 'AE',
    logo: '/images/lexilelogo.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  classboom: {
    prefix: 'AE',
    logo: '/images/lexilelogo.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  haveenglish: {
    prefix: 'AE',
    logo: '/images/lexilelogo.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  'smart-eclass': {
    prefix: 'SM',
    logo: '/images/smart-eclass-logo2.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: false,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#e599f7',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      w4: 'Listen',
      w5: 'Practice',
      w6: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
      quiz: 'Quiz',
    },
    stageLevelCategory: {
      1: ['voca_low'],
      2: ['voca_low'],
      3: ['voca_high'],
      4: ['voca_high'],
      5: ['voca_high'],
      6: ['voca_high'],
      7: ['voca_high'],
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
    bookSort: function (bookSeries) {
      if (bookSeries) {
        const ltrs = bookSeries.filter((e) => e.book_code.includes('LTR'));
        const hph = bookSeries.filter((e) => e.book_code.includes('HPH'));
        const rest = bookSeries.filter((e) => !e.book_code.includes('LTR') && !e.book_code.includes('HPH'));
        rest.splice(1, 0, ...ltrs);

        const five = rest.splice(5, 1);
        rest.splice(7, 0, ...five);
        rest.splice(5, 0, ...hph);
        return rest;
      }
    },
    isTalkingTap: function (companyName, lessonCode) {
      if (companyName === 'smart-eclass' && lessonCode.includes('TT')) {
        return true;
      } else return false;
    },
    bookTitle: function (companyName, lessonCode) {
      if (lessonCode.includes('VT')) {
        return 'Voca Tap';
      }
      if (lessonCode.includes('TT')) {
        return 'Talking Tap';
      }
      if (companyName === 'smart-eclass') {
        return 'Smart E Class';
      } else return 'Sneakers';
    },
  },
  englishtap: {
    prefix: 'LT',
    logo: '/images/englishtap-logo.png',
    isIgnoreAdress: true,
    syncActive: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: false,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#e599f7',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      w4: 'Listen',
      w5: 'Practice',
      w6: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
      quiz: 'Quiz',
    },
    stageLevelCategory: {
      1: ['voca_low'],
      2: ['voca_low'],
      3: ['voca_high'],
      4: ['voca_high'],
      5: ['voca_high'],
      6: ['voca_high'],
      7: ['voca_high'],
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
    bookSort: function (bookSeries) {
      if (bookSeries) {
        const ltrs = bookSeries.filter((e) => e.book_code.includes('LTR'));
        const hph = bookSeries.filter((e) => e.book_code.includes('HPH'));
        const rest = bookSeries.filter((e) => !e.book_code.includes('LTR') && !e.book_code.includes('HPH'));
        rest.splice(1, 0, ...ltrs);

        const five = rest.splice(5, 1);
        rest.splice(7, 0, ...five);
        rest.splice(5, 0, ...hph);
        return rest;
      }
    },
    isTalkingTap: function (companyName, lessonCode) {
      if (companyName === 'smart-eclass' && lessonCode.includes('TT')) {
        return true;
      } else return false;
    },
    bookTitle: function (companyName, lessonCode) {
      if (lessonCode.includes('VT')) {
        return 'Voca Tap';
      }
      if (lessonCode.includes('TT')) {
        return 'Talking Tap';
      }
      if (companyName === 'smart-eclass') {
        return 'Smart E Class';
      } else return 'Sneakers';
    },
  },
  sneakersedu: {
    prefix: 'SK',
    logo: '/images/sneakers_bi_new.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: false,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      w4: 'Listen',
      w5: 'Practice',
      w6: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
      quiz: 'Quiz',
    },
    stageLevelCategory: {
      1: ['voca_low'],
      2: ['voca_low'],
      3: ['voca_high'],
      4: ['voca_high'],
      5: ['voca_high'],
      6: ['voca_high'],
      7: ['voca_high'],
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
    bookSort: function (bookSeries) {
      // return bookSeries
      const tmp = [];
      if (bookSeries) {
        const moveidx = [0, 3, 1, 8, 7, 10, 6, 4, 9, 11, 2, 5];
        for (let i = 0; i < bookSeries.length; i++) {
          const currentIndex = moveidx[i];

          tmp.push({ ...bookSeries[i], no: currentIndex });
        }
        return tmp.sort((a, b) => a.no - b.no);
      }
    },
    isTalkingTap: function (companyName, lessonCode) {
      if (companyName === 'sneakersedu' && lessonCode.includes('TT')) {
        return true;
      } else return false;
    },
    bookTitle: function (companyName, lessonCode) {
      if (lessonCode.includes('VT')) {
        return 'Voca Tap';
      }
      if (lessonCode.includes('TT')) {
        return 'Talking Tap';
      }
      if (companyName === 'smart-eclass') {
        return 'Smart E Class';
      } else return 'Sneakers';
    },
  },
  educosneakers: {
    prefix: 'SK',
    logo: '/images/educo_logo1.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: false,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      w4: 'Listen',
      w5: 'Practice',
      w6: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
      quiz: 'Quiz',
    },
    stageLevelCategory: {
      1: ['voca_low'],
      2: ['voca_low'],
      3: ['voca_high'],
      4: ['voca_high'],
      5: ['voca_high'],
      6: ['voca_high'],
      7: ['voca_high'],
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
    bookTitle: function (companyName, lessonCode) {
      if (companyName === 'sneakersedu' || 'educosneakers') {
        if (lessonCode.includes('TT')) {
          return 'Talking Tap';
        } else return 'Sneakers';
      } else return '';
    },
  },
  'ai-eng': {
    prefix: 'SK',
    logo: '/images/educo_logo1.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
  },
  educo: {
    prefix: 'SK',
    logo: '/images/educo_logo1.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: false,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      w4: 'Listen',
      w5: 'Practice',
      w6: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
      quiz: 'Quiz',
    },
    stageLevelCategory: {
      1: ['voca_low'],
      2: ['voca_low'],
      3: ['voca_high'],
      4: ['voca_high'],
      5: ['voca_high'],
      6: ['voca_high'],
      7: ['voca_high'],
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
    bookTitle: function (companyName, lessonCode) {
      if (companyName === 'sneakersedu' || 'educosneakers') {
        if (lessonCode.includes('TT')) {
          return 'Talking Tap';
        } else return 'Sneakers';
      } else return '';
    },
  },
  semtle: {
    prefix: 'AE',
    logo: '/images/lexilelogo.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_listening1: '#a0d468',
      homework_listening2: '#a0d468',
      homework_reading1: '#a0d468',
      homework_reading2: '#a0d468',
      homework_grammar1: '#a0d468',
      homework_grammar2: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  creo: {
    prefix: 'CR',
    logo: '/images/creo-logo.png',
    loginLogo: '/images/login-creo-logo.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: false,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_listening1: '#C7B2CE',
      homework_listening2: '#C7B2CE',
      homework_reading1: '#C7B2CE',
      homework_reading2: '#C7B2CE',
      homework_grammar1: '#C7B2CE',
      homework_grammar2: '#C7B2CE',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      st: 'Small Talk',
      //vc1: 'Voca Intro',
      vc1: 'Intro',
      //vc2: 'Voca Meaning',
      vc2: 'Meaning',
      //vc3: 'Voca Usage',
      vc3: 'Usage',
      //ks1: 'Key Sentences Intro',
      ks1: 'Intro',
      //ks2: 'Key Sentences Listen',
      ks2: 'Listening',
      ks3: 'Unscramble',
      ks4: 'Dictation',
      quiz: 'Quiz',
      //sr: 'Speed Reading',
      sr: 'Acc. / WPM',
      dr: 'Drills',
      rp: 'Role Play',
    },
    syncActive: true,
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  lucid: {
    prefix: 'LC',
    logo: '/images/lucid_logo.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 문성필  사업자등록번호:471-87-00799 <br /> 주소: 경기도 군포시 고산로 681, 301호(산본동, 성원빌딩) <br /> 연락처: 1522-1605  메일: lucid1015@naver.com',
    privacyCompany: '(주)루시드영어',
    privacyHeader: '루시드 영어 개인정보취급방침',
    companyName: 'Lucid English',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#0070c0',
      homework_listening1: '#0070c0',
      homework_reading: '#ff0000',
      homework_reading1: '#ff0000',
      homework_grammar: '#00b050',
      homework_grammar1: '#00b050',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      'homework_phonics-voca': '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#FF0000',
      UserLesson: '#FF0000',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#000066',
      AssignedDailyTests: '#000066',
      // AssignedBooks: 'blue',
      AssignedBooks: '#FF9900',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Flashcard',
      w2: 'Practice',
      ks1: 'Flashcard',
      ks2: 'Pattern Drills',
      ks3: 'Unscramble',
      st: 'Small Talk',
      w3: 'Dictation',
      w4: 'Quiz',
      ks4: 'Sentence Dictation',
      sq2: 'Fluency',
      sq1: 'Word Test',
      sq3: 'Sentence Test',
      sq4: 'Role play',
      ws1: 'Flascard',
      ws2: 'Practice',
      ws3: 'Flascard',
      ws4: 'Practice',
      wq1: 'Quiz',
      wq2: 'Quiz',
    },
    stageLevelCategory: {
      1: ['grammar', 'listening', 'reading'],
      2: ['grammar', 'listening', 'reading'],
      3: ['grammar', 'listening', 'reading'],
      4: ['grammar', 'listening', 'reading'],
      5: ['grammar', 'listening', 'reading'],
      6: ['grammar1', 'listening1', 'reading1'],
      7: ['grammar1', 'listening1', 'reading1'],
    },
    perform: 'O',
    notPerform: 'X',
    showExtraField: 'true',
  },
  poppins: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  'pub-school': {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_userlesson',
      OfflineLesson: 'icon_userlesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  'w-english': {
    prefix: 'WAE',
    logo: '/images/wenglish.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
  },
  w_english: {
    prefix: 'WAE',
    logo: '/images/wenglish.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  evine: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'evine',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: false,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      // bookr: 'orangered',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  gnb: {
    prefix: 'AE',
    loginLogo: '/images/gnb-BookPi.png',
    logo: '/images/gnb-BookPi.png',
    isIgnoreAdress: true,
    syncActive: true,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#e599f7',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  gnb2: {
    prefix: 'AE',
    loginLogo: '/images/gnb-BookPi.png',
    logo: '/images/gnb-BookPi.png',
    isIgnoreAdress: true,
    syncActive: true,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#e599f7',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  whaleenglish: {
    prefix: 'AE',
    logo: '/images/library-300-logo.png',
    isIgnoreAdress: true,
    syncActive: true,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'WhaleEnglish',
    companyName: 'WhaleEnglish',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#e599f7',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  educloud: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: true,
    syncActive: true,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#e599f7',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  dthufs: {
    prefix: 'AE',
    loginLogo: '/images/DTHUFS-logo.png',
    logo: '/images/gnb-BookPi.png',
    isIgnoreAdress: true,
    syncActive: true,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      // ClassLesson: 'Home Work',
      // UserLesson: 'Home Work',
      // AssignedTests: 'Test',
      // ZoomSchedule: 'Zoom Classes',
      // AssignedBooks: 'E-Library',
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_phonics: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#e599f7',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      w1: 'Listen',
      w2: 'Practice',
      w3: 'Type',
      ks1: 'Unscramble',
      ks2: 'Dictation',
      game: 'Game',
      speak: 'AI Speaking',
      rq: 'Reading Quiz',
      lq: 'Listening Quiz',
      gq: 'Grammar Quiz',
      wu: 'Unscramble',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  intoreading: {
    prefix: 'ELT',
    logo: '/images/intologo_1.png',
    loginLogo: '/images/intologo_2.png',
    isIgnoreAdress: true,
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_video: '#a0d468',
      homework_grammar: '#a0d468',
      homework_listening1: '#C7B2CE',
      homework_listening2: '#C7B2CE',
      homework_reading1: '#C7B2CE',
      homework_reading2: '#C7B2CE',
      homework_grammar1: '#C7B2CE',
      homework_grammar2: '#C7B2CE',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    stageNames: {
      st: 'Small Talk',
      //vc1: 'Voca Intro',
      vc1: 'Intro',
      //vc2: 'Voca Meaning',
      vc2: 'Meaning',
      //vc3: 'Voca Usage',
      vc3: 'Usage',
      //ks1: 'Key Sentences Intro',
      ks1: 'Intro',
      //ks2: 'Key Sentences Listen',
      ks2: 'Listening',
      ks3: 'Unscramble',
      ks4: 'Dictation',
      quiz: 'Quiz',
      //sr: 'Speed Reading',
      sr: 'Acc. / WPM',
      dr: 'Drills',
      rp: 'Role Play',
    },
    syncActive: true,
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  aone: {
    prefix: 'AE',
    logo: '/images/classboom-logo-o.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_daily_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
  rise: {
    prefix: 'RP',
    books: ['RP', 'RW', 'RR', 'RL'],
    logo: '/images/login_rise.png',
    isIgnoreAdress: false,
    address:
      '대표이사: 송석호 사업자등록번호: 107-88-42105 주소: 서울시 강남구 선릉로 428, 위워크빌딩 10-103호, 10-104호, 10-107호 (우)06192<br />연락처: 02-555-4529 메일:song@cloubot.com',
    privacyCompany: '(주)클라우봇은',
    privacyHeader: 'ClassBoom 개인정보취급방침',
    companyName: 'Cloubot',
    classType: {
      ClassLesson: 'H',
      UserLesson: 'H',
      PhonicsLesson: 'P',
      StarwordsLesson: 'S',
      OfflineLesson: 'O',
      AssignedTests: 'T',
      AssignedDailyTests: 'DT',
      ZoomSchedule: 'Z',
      AssignedBooks: 'L',
      ExternalStudy: 'E',
      Bookr: 'B',
      ExternalStudyForStudent: 'N',
      AITopicUserAssignmentCount: 'AI',
      AITopicStudentAssignmentCount: 'AI',
      LevelUp: 'L',
      StarWords: 'S',
    },
    showCountButtons: true,
    appColors: {
      homework: '#f5a623',
      homework_recording: '#ea4444',
      homework_recording_done: '#ea4444',
      homework_essay: '#a0d468',
      homework_lesson: '#ffce54',
      homework_review: '#ffce54',
      homework_rc: '#ffce54',
      homework_expr: '#ffce54',
      homework_voca: '#ffce54',
      homework_sentence: '#5d9cec',
      homework_listening: '#a0d468',
      homework_reading: '#a0d468',
      homework_grammar: '#a0d468',
      homework_writing: '#FF0000',
      homework_speaking: '#FF0000',
      homework_lc_low: '#a0d468',
      homework_lc_high: '#a0d468',
      homework_rc_low: '#a0d468',
      homework_rc_high: '#a0d468',
      homework_listening1: '#a0d468',
      homework_listening2: '#a0d468',
      homework_reading1: '#a0d468',
      homework_reading2: '#a0d468',
      homework_grammar1: '#a0d468',
      homework_grammar2: '#a0d468',
      done: '#dee2e6',
      test: '#ff7f00',
      lesson: '#f5a623',
      book: '#f5a623',
      zoom: '#f2ac52',
      other: '#f5a623',
      evineBook: '#6eb2c3',
      offline: '#4e9b4e',
      phonics: '#ffce54',
      externalStudy: '#6e93c1',
      bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    classColor: {
      //ClassLesson: '#11783D',
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      OfflineLesson: '#7030A0',
      PhonicsLesson: '#ffce54',
      AssignedTests: '#ff7f00',
      AssignedDailyTests: '#ff7f00',
      // AssignedBooks: 'blue',
      AssignedBooks: 'blue',
      ZoomSchedule: '#3C79F6',
      ExternalStudy: '#6e93c1',
      Bookr: 'orangered',
      LevelUp: '#ffa700',
      News_O_Matic: '#FF0000',
      AI_Topics: '#5d9cec',
      Book_Report: '#DE7BFD',
      AI_Talking: '#5d9cec',
      StarWords: '#0a356b',
    },
    calendarEventsIn: true,
    eventsIcon: {
      ClassLesson: 'icon_userlesson',
      UserLesson: 'icon_userlesson',
      PhonicsLesson: 'icon_phonicsLesson',
      OfflineLesson: 'icon_offlineLesson',
      AssignedTests: 'icon_assignedtests',
      AssignedDailyTests: 'icon_daily_assignedtests',
      ZoomSchedule: 'icon_zoomschedule',
      AssignedBooks: 'icon_assignedBooks',
      ExternalStudy: 'icon_externalStudy',
      Bookr: 'icon_externalStudyBookr',
      LevelUp: 'icon_assignedBooks',
      ExternalStudyForStudent: 'icon_externalStudyStudent',
      AITopicUserAssignmentCount: 'icon_aiTopicUserAssignment',
      AITopicStudentAssignmentCount: 'icon_aiTopicUserAssignment',
    },
    syncActive: true,
    perform: '완료',
    notPerform: '미수행',
    showExtraField: 'false',
  },
};
