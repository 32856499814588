import { gql } from '@apollo/client';

export const USER_CLASS_LESSON_LIST = gql`
  query getUserClassLessonList($class_idx: Int!, $user_idx: Int!, $study_range: [String!], $book_codes: [String!], $is_deleted: Int) {
    getUserClassLessonList(
      class_idx: $class_idx
      user_idx: $user_idx
      study_range: $study_range
      book_codes: $book_codes
      is_deleted: $is_deleted
    ) {
      idx
      study_date
      complete_date
      class {
        name
      }
      book_lesson {
        unit_no
        day_no
        stage_group_code
        title
        code
        book {
          parent_code
          code
          book_sub {
            title
          }
        }
      }
      user_study {
        lesson_code
        stage_no
        exam_total
        exam_correct
        wpm
        recording_data
        user_lesson_idx
        study_date
        user_study_answer{
          is_correct
          question_no
          user_answer
        }
      }
    }
  }
`;

export const BOOK_STAGE_LIST = gql`
  query bookStageList($code: String!) {
    bookStageList(code: $code) {
      stage_no
      name_abbr
      book_stage {
        name
        no
        group_code
      }
    }
  }
`;

export const BOOK_STAGE_LIST_BY_BOOK_CODE = gql`
  query bookStageListByBookCode($book_code: String!) {
    bookStageListByBookCode(book_code: $book_code) {
      stage_no
      name_abbr
      book_stage {
        name
        name_abbr
        no
        group_code
        category_name
        ai_stage_code
      }
    }
  }
`;

export const BOOK_STAGE_LIST_BY_BOOK_CODES = gql`
  query bookStageListByBookCodes($book_codes: [String!]!) {
    bookStageListByBookCodes(book_codes: $book_codes) {
      stage_no
      name_abbr
      book_code
      book_stage {
        name
        name_abbr
        no
        group_code
        category_name
      }
    }
  }
`;

export const ELIBRARY_STUDY_COMPLETE_STATIS_LIST = gql`
  query elibraryStudyCompleteStatisList($start: String!, $end: String!) {
    elibraryStudyCompleteStatisList(start: $start, end: $end) {
      user_idx
      latest_date
      latest_book
      read_book_count
      average_grade
      user {
        name
        class_student {
          class {
            name
            idx
          }
        }
      }
    }
  }
`;

export const STUDENT_BOOK_LIST = gql`
  query studentBookList($user_idx: Int!) {
    studentBookList(user_idx: $user_idx)
  }
`;

export const STUDENT_BOOK_STUDY_RESULT = gql`
  query studentBookStudyResult($user_idx: Int!, $book_code: String!) {
    studentBookStudyResult(user_idx: $user_idx, book_code: $book_code)
  }
`;

export const STUDENT_ELIBRARY_STUDY_LIST = gql`
  query studentElibraryStudyList($user_idx: Int!, $ym: String!) {
    studentElibraryStudyList(user_idx: $user_idx, ym: $ym)
  }
`;

export const ALL_USER_CLASS_LESSION_LIST = gql`
  query getAllUserClassLessonList($class_idx: Int!) {
    getAllUserClassLessonList(class_idx: $class_idx) {
      user_idx
      book_lesson {
        stage_group_code
      }
      user_study {
        lesson_code
        stage_no
        exam_total
        exam_correct
        user_lesson_idx
      }
    }
  }
`;

export const ALL_USER_CLASS_LESSION_WITH_BOOK_LIST = gql`
  query getAllUserClassLessonList($class_idx: Int!, $study_range: [String!], $book_codes: [String!]) {
    getAllUserClassLessonList(class_idx: $class_idx, study_range: $study_range, book_codes: $book_codes) {
      user_idx
      book_lesson {
        stage_group_code
        book {
          code
        }
        code
      }
      user_study {
        lesson_code
        stage_no
        exam_total
        exam_correct
        user_lesson_idx
      }
    }
  }
`;
export const EXTERNAL_STUDY_RANKING_LIST = gql`
  query externalStudyRankingList($company_idx: Int!, $start: String!, $end: String!, $type: String!, $campus_idx: Int) {
    externalStudyRankingList(company_idx: $company_idx, start: $start, end: $end, type: $type, campus_idx: $campus_idx)
  }
`;
export const MY_EXTERNAL_STUDY_GOAL = gql`
  query myExternalStudyGoal($ym: String, $type: String) {
    myExternalStudyGoal(ym: $ym, type: $type) {
      cntGoal
      cntRead
      cntTotal
      leftDay
      month
      rate
    }
  }
`;

export const GET_USER_STUDY_COMPLETE_LIST = gql`
  query getUserStudyCompleteList($user_lesson_idx: Int!) {
    getUserStudyCompleteList(user_lesson_idx: $user_lesson_idx) {
      stage_no
    }
  }
`;
